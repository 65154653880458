import * as React from "react";
import styled from "styled-components";

const maglabLogo = require("./assets/images/MagLab-logo.png");
const openknectLogo = require("./assets/images/openknect-logo.jpeg");
const cicompassLogo = require("./assets/images/cicompass-logo.png");
const cfdLogo = require("./assets/images/cfd-logo.jpeg");
const lspaceLogo = require("./assets/images/lspace-logo.png");
const ritLogo = require("./assets/images/rit-logo.png");
const nasaLogo = require("./assets/images/nasa-logo.png");

const experiences = [
  {
    id: 1,
    logo: nasaLogo,
    company: "National Aeronautics and Space Administration (NASA - GSFC)",
    position: "Data Science Intern (OSTEM)",
    duration: "August 2024 - December 2024",
    description:
      "• Conducting quantitative, statistical, and visual analyses of global atmospheric data using the Goddard Earth Observing System Model, Version 5 (GEOS-5) \n • Integrate simulation data with observational data to assess atmospheric phenomena, with a focus on the mesosphere. \n • Collaborate with a multi-agency team, utilizing Python, IDL, and MATLAB for data analysis and model evaluation.",
    skills:
      "Python | IDL | MATLAB | Data Analysis | Climate Modeling | Atmospheric Science",
  },

  {
    id: 2,
    logo: maglabLogo,
    company: "National High Magnetic Field Laboratory (MagLab)",
    position: "Machine Learning Engineer Co-op",
    duration: "May 2024 - July 2024",
    description:
      "• Developed programs for coil voltage deviation forecasting and power supply failure prediction using time-series forecasting and machine learning models, enhancing maintenance scheduling.\n • Implemented Convolutional Neural Networks for audio event classification, detecting hazardous conditions via ultrasonic probe data analysis. \n • Applied data augmentation techniques to enhance model robustness and feature extraction methods to optimize model performance.\n • Experimented with LSTM models for multivariate forecasting, using frameworks like TensorFlow-Keras and Darts, and tuned hyperparameters with Optuna. \n • Gained experience in code deployment and integration with Slack for real-time notifications.",
    skills:
      "Time Series Forecasting | Python | Machine Learning | LSTM | CNN | Scikit-learn | TensorFlow | Darts | Optuna",
  },
  {
    id: 3,
    logo: cicompassLogo,
    company: "CI Compass",
    position: "Student Fellow",
    duration: "Jan 2024 - May 2024",
    description:
      "• Accepted into selective NSF-funded fellowship to develop technical and research skills (Python, Cloud Computing, AI/ML, etc.) to prepare to contribute to real-world cyberinfrastructure challenges for Major Facilities. \n • Gained insight into common challenges faced by NSF facilities in cyberinfrastructure and developed strategies for tackling them. \n • Interact with professionals at major facilities and participate in workshops on cyberinfrastructure, HPC, cloud computing, machine learning, and FAIR data.",
    skills:
      "Cyberinfrastructure | HPC | Cloud Computing | FAIR data | Machine Learning | Python | Linux",
  },
  {
    id: 4,
    logo: cfdLogo,
    company: "Center for Detectors at RIT",
    position: "Undergraduate Researcher",
    duration: "Nov 2023 - May 2024",
    description:
      "• Utilize Python (NumPy, SciPy, Matplotlib) and MATLAB to create a software pipeline for analyzing and cross-correlating images from the Cosmic Infrared Background Experiment (CIBER and CIBER-2). \n • Conducted Fourier cross-correlations between CIBER-2 and CIBER-1 data, interpreted results within the intra-halo light model, and identified systematic effects, enhancing reliability. \n • Mastered image calibration and analysis, gaining insight into working with complex astronomical datasets and effectively constraining systematic errors.",
    skills:
      " Python | MATLAB | Matplotlib | Numpy | Scipy | Image Processing | Data Analysis | Astronomy",
  },
  {
    id: 5,
    logo: openknectLogo,
    company: "OpenKnect",
    position: "Software Engineer Intern",
    duration: "May 2023 - August 2023",
    description:
      "• Collaborated with a team of developers and UX/UI designers to implement a web application using MVC, Flutter, Dart, Git, Figma, and Firebase technologies \n • Utilized media queries and best practices to achieve fully responsive websites for desktop, tablet, and mobile users, ensuring optimal user experiences with a 10% increase in mobile user engagement and an average page load time decrease of 20% through front-end optimization techniques \n • Actively engaged in code reviews and collaborative version control using Git to maintain code quality and best practices",
    skills: "Dart | Flutter | Firebase | Git | Responsive Design | Scrum",
  },
  {
    id: 6,
    logo: lspaceLogo,
    company: "NASA L'SPACE Proposal Writing & Evaluation Experience (NPWEE)",
    position: "Innovator/Software Engineer",
    duration: "May 2023 - August 2023",
    description:
      "• Participated in NASA's 12-week L'Space program as a Software Engineer and Innovator in a 12-member team. Collaborated with an interdisciplinary, distributed virtual team to develop a unique proposal to help meet NASA's strategic goals, exercising creative problem-solving, and producing deliverables on weekly deadlines.  \n • Contributed as a Software Engineer and Innovator to create a New Technology Report (NTR) for NASA. Assisted in crafting a technical proposal for a Tumbling Ball Rover for NASA Marshall’s Chief Technologist. \n • Served as Primary reviewer on an evaluation panel to accurately analyze and score proposals from other academy teams.",
    skills:
      "Teamwork | Proposal Writing | Technological Innovation | Project Management | Problem Solving",
  },
  {
    id: 7,
    logo: ritLogo,
    company: "Rochester Institute of Technology",
    position: "Computer Science Grader",
    duration: "Aug 2022 - Dec 2022",
    description:
      "• Worked as a grader for Concepts of Parallel and Distributed Systems course. Responsible for preparing and grading assignments and quizzes, and providing constructive feedback for improvisation",
    skills:
      "C# | .NET | Multithreaded Development | Computer Networks and Network Protocols",
  },
];
function MyExperience() {
  return (
    <ExperienceSection id="experience-section">
      <ExperienceHeader>Professional Experience</ExperienceHeader>
      {/* <ExperienceDescription>
        Lorem ipsum dolor sit amet consectetur. Imperdiet convallis blandit
        felis ligula aliquam
      </ExperienceDescription> */}
      <ExperienceList>
        <ExperienceRow>
          {experiences.slice(0, 2).map((experience) => (
            <ExperienceColumn key={experience.id}>
              <ExperienceCard>
                <CompanyLogo
                  src={experience.logo}
                  alt={`${experience.company} logo`}
                />
                <ExperienceTitle>
                  {experience.company}
                  <br />- {experience.position}
                  <br />
                  <ExperienceDuration>{experience.duration}</ExperienceDuration>
                </ExperienceTitle>
                <ExperienceDetails>{experience.description}</ExperienceDetails>
                <SkillsApplied>{experience.skills}</SkillsApplied>
              </ExperienceCard>
            </ExperienceColumn>
          ))}
        </ExperienceRow>
        <ExperienceRow>
          {experiences.slice(2, 4).map((experience) => (
            <ExperienceColumn key={experience.id}>
              <ExperienceCard>
                <CompanyLogo
                  src={experience.logo}
                  alt={`${experience.company} logo`}
                />
                <ExperienceTitle>
                  {experience.company}
                  <br />- {experience.position}
                  <br />
                  <ExperienceDuration>{experience.duration}</ExperienceDuration>
                </ExperienceTitle>
                <ExperienceDetails>{experience.description}</ExperienceDetails>
                <SkillsApplied>{experience.skills}</SkillsApplied>
              </ExperienceCard>
            </ExperienceColumn>
          ))}
        </ExperienceRow>
        <ExperienceRow>
          {experiences.slice(4, 6).map((experience) => (
            <ExperienceColumn key={experience.id}>
              <ExperienceCard>
                <CompanyLogo
                  src={experience.logo}
                  alt={`${experience.company} logo`}
                />
                <ExperienceTitle>
                  {experience.company}
                  <br />- {experience.position}
                  <br />
                  <ExperienceDuration>{experience.duration}</ExperienceDuration>
                </ExperienceTitle>
                <ExperienceDetails>{experience.description}</ExperienceDetails>
                <SkillsApplied>{experience.skills}</SkillsApplied>
              </ExperienceCard>
            </ExperienceColumn>
          ))}
        </ExperienceRow>
        <ExperienceRow>
          {experiences.slice(6, 7).map((experience) => (
            <ExperienceColumn key={experience.id}>
              <ExperienceCard>
                <CompanyLogo
                  src={experience.logo}
                  alt={`${experience.company} logo`}
                />
                <ExperienceTitle>
                  {experience.company}
                  <br />- {experience.position}
                  <br />
                  <ExperienceDuration>{experience.duration}</ExperienceDuration>
                </ExperienceTitle>
                <ExperienceDetails>{experience.description}</ExperienceDetails>
                <SkillsApplied>{experience.skills}</SkillsApplied>
              </ExperienceCard>
            </ExperienceColumn>
          ))}
        </ExperienceRow>
      </ExperienceList>
      {/* <ExperienceCard>
        <CompanyLogo
          src={experiences[4].logo}
          alt={`${experiences[4].company} logo`}
        />
        <ExperienceTitle></ExperienceTitle>
          {experiences[4].company} - {experiences[4].position}
          <br />
          <ExperienceDuration>{experiences[4].duration}</ExperienceDuration>
        </ExperienceTitle>
        <ExperienceDetails>{experiences[4].description}</ExperienceDetails>
      </ExperienceCard> */}
    </ExperienceSection>
  );
}

export default MyExperience;

const ExperienceSection = styled.section`
  background-color: var(--blck, #211d25);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px 0 80px;
`;
const ExperienceHeader = styled.h2`
  color: var(--Foundation-White-Light, #fefefe);
  text-align: justify;
  letter-spacing: 1.2px;
  margin-top: 70px;
  font: 700 40px Lato, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
const ExperienceDescription = styled.p`
  color: var(--newwwwwwwwwwww, #959595);
  text-align: justify;
  letter-spacing: 0.6px;
  margin-top: 18px;
  font: 500 20px/160% Lato, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const ExperienceList = styled.div`
  padding-left: 45px;
  padding-right: 45px;
  justify-content: center;
  display: flex;
  margin-top: 40px;
  // width: 100%;
  // max-width: 1609px;
  flex-direction: column;
  @media (max-width: 991px) {
    // max-width: 100%;
    margin-top: 40px;
  }
`;
const ExperienceRow = styled.div`
  gap: 40px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;
const ExperienceColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const ExperienceCard = styled.div`
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.04);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  // width: 100%;
  padding: 34px;
  align-items: center;
  margin-top: 34px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;
const CompanyLogo = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 70px;
`;
const ExperienceTitle = styled.h3`
  color: var(--purp, #d514ce);
  text-align: center;
  letter-spacing: 0.72px;
  margin-top: 16px;
  font: 700 24px/38px Lato, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-right: 10px;
  }
`;
const ExperienceDuration = styled.span`
  font-weight: 400;
  font-size: 20px;
`;
const ExperienceDetails = styled.p`
  color: var(--newwwwwwwwwwww, #959595);
  text-align: left;
  letter-spacing: 0.6px;
  margin-top: 34px;
  font: 500 20px/32px Lato, sans-serif;
  white-space: pre-line;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SkillsApplied = styled.p`
  color: var(--purp, #d514ce);
  text-align: justify;
  letter-spacing: 0.72px;
  margin-top: auto; /* Align to the bottom */
  font: 500 14px/30px Lato, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin
  }
`;
