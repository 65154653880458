import * as React from "react";
import styled from "styled-components";
import AboutMe from "./AboutMe";
import { Link } from "react-scroll";
const profileImage = require("./assets/images/profile_nbg.png");
const gmailLogo = require("./assets/images/mdi_instagram.png");
const linkedinLogo = require("./assets/images/linkedin_logo.png");
const githubLogo = require("./assets/images/github_logo.png");
const phoneLogo = require("./assets/images/phone_logo.png");
// const resume =
//   "https://drive.google.com/file/d/1xwg11yMcDQYgqF_Hio9S48d45aNcp9Pg/view?usp=sharing";

const resume =
  "https://drive.google.com/file/d/1G6LVfx19iDULs9uSr2ofVkYXPLnKxwll/view?usp=drive_link";

const gmailLink = "mailto:qq1990@g.rit.edu";
const linkedinLink = "https://www.linkedin.com/in/quan-quy-ba9869221/";
const githubLink = "https://github.com/qq1990";
const phoneLink = "tel:+16038587153";
function MyIntroduction() {
  return (
    <HeroSection id="home-section">
      <HeroContent>
        <HeroTextWrapper>
          <HeroSubtitle>Hi I am</HeroSubtitle>
          <HeroTitle>Quan Quy</HeroTitle>
          <HeroDescription>Software Engineer</HeroDescription>
          <SocialIcons>
            <a href={gmailLink} target="_blank" rel="noopener noreferrer">
              <SocialIcon src={gmailLogo} alt="Gmail" />
            </a>
            <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
              <SocialIcon src={linkedinLogo} alt="LinkedIn" />
            </a>
            <a href={githubLink} target="_blank" rel="noopener noreferrer">
              <SocialIcon src={githubLogo} alt="GitHub" />
            </a>
            <a href={phoneLink} target="_blank" rel="noopener noreferrer">
              <SocialIcon src={phoneLogo} alt="Phone" />
            </a>
          </SocialIcons>
          <HeroActions>
            <Link to="skills-section" smooth={true} duration={500}>
              <HireButton>My Skills</HireButton>
            </Link>
            <a href={resume} target="_blank" rel="noopener noreferrer">
              <ResumeButton>See my Resume</ResumeButton>
            </a>
          </HeroActions>
        </HeroTextWrapper>
        <HeroImage src={profileImage} alt="Profile Image" />
      </HeroContent>
      <AboutSection id="about-section">
        <AboutMe />
      </AboutSection>
    </HeroSection>
  );
}

export default MyIntroduction;

const HireButton = styled.button`
  background-color: #d514ce;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 19px 40px;
  font: 16px Lato, sans-serif;
  font-weight: 700;
  letter-spacing: 0.48px;
  cursor: pointer;

  @media (max-width: 991px) {
    padding: 12px 20px;
  }
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 12px;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const HeroContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1609px;
  width: 100%;
  // padding-top: 73px;
  gap: 20px;
  // margin: 73px 33px 0;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin: 40px 10px 0 0;
  }
`;

const HeroTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: auto 0;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const HeroSubtitle = styled.h2`
  color: var(--newwwwwwwwwwww, #959595);
  font: 600 24px Lato, sans-serif;
  letter-spacing: 0.72px;
`;

const HeroTitle = styled.h1`
  color: var(--newwwwwwwwwwww, #959595);
  font: 700 28px Lato, sans-serif;
  letter-spacing: 0.84px;
  margin-top: 10px;
`;

const HeroDescription = styled.p`
  background: linear-gradient(
    90deg,
    #d514ce 0.34%,
    #d514ce 42.06%,
    #d514ce 90.34%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: 900 70px Lato, sans-serif;
  letter-spacing: 2.1px;
  margin-top: 24px;

  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 17px;
  padding-top: 16px;
`;

const SocialIcon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(87, 87, 87, 1);
  background-color: rgba(255, 255, 255, 0.04);
  object-fit: cover;
`;

const HeroActions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 61px;
  width: 395px;
  max-width: 100%;
  font-size: 20px;
  font-weight: 700;
  text-align: justify;
  letter-spacing: 0.6px;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const ResumeButton = styled.button`
  background-color: transparent;
  color: var(--newwwwwwwwwwww, #959595);
  border: 2px solid rgba(149, 149, 149, 1);
  border-radius: 8px;
  padding: 12px 40px;
  font: 700 20px Lato, sans-serif;
  cursor: pointer;

  @media (max-width: 991px) {
    padding: 12px 20px;
  }
`;

const HeroImage = styled.img`
  width: 40%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 50%;
  // border: 2px solid rgba(149, 149, 149, 1);

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AboutSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  padding: 0 80px;
  margin-top: 80px;

  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;
