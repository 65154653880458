import * as React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

function MyLandingPage() {
  return (
    <MainContainer>
      <Container>
        <BackgroundImage
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/a7fe841ec106acc967de45e5430e16e553f6f9f9fd954e9b95567f5160168d01?apiKey=64874d91905343b0a6103f03c78f2eb8&"
          alt=""
        />
        <ContentWrapper>
          <WelcomeTitle>Welcome to my personal website</WelcomeTitle>
          <Content>
            <ReusableBlock
              imgSrc1="https://cdn.builder.io/api/v1/image/assets/TEMP/8748c97335463433b123e1ef361400f34ad4776ed1981ba076622a93ba11ff80?apiKey=64874d91905343b0a6103f03c78f2eb8&"
              imgAlt1="2D website image"
              imgSrc2="https://cdn.builder.io/api/v1/image/assets/TEMP/4154c68fd016b4a3ec537fde1c3c5e279b913b3b681858d6b40351d209ad0a94?apiKey=64874d91905343b0a6103f03c78f2eb8&"
              imgAlt2="Alternative description for second image"
              buttonText="Enter my 2D website"
              description="Discover my experiences and projects quickly and efficiently. Navigate through my professional journey, explore my portfolio, and get to know my skills and achievements in a concise and user-friendly format."
              link="/2d"
            />
            <ReusableBlock
              imgSrc1="https://cdn.builder.io/api/v1/image/assets/TEMP/7b2144c02cc25c89364ab06535113fdaa330352a470d525f76630ae452715e97?apiKey=64874d91905343b0a6103f03c78f2eb8&"
              imgAlt1="Alternative description for first image"
              imgSrc2="https://cdn.builder.io/api/v1/image/assets/TEMP/df8829e9acdd2d120c65af61392077aad5ff603f7b737fe4f36bfcc83c3a9d19?apiKey=64874d91905343b0a6103f03c78f2eb8&"
              imgAlt2="Alternative description for second image"
              buttonText="Enter my 3D website"
              description="Immerse yourself in a dynamic 3D showroom where you can interact with my portfolio. Experience my work in an engaging and innovative way, exploring projects and accomplishments through an interactive and visually stunning platform."
              link="/3dspace-portfolio/"
            />
          </Content>
        </ContentWrapper>
      </Container>
    </MainContainer>
  );
}

const ReusableBlock = ({
  imgSrc1,
  imgAlt1,
  imgSrc2,
  imgAlt2,
  buttonText,
  description,
  link,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };

  return (
    <StyledColumn>
      <StyledBlock>
        <img src={imgSrc1} alt={imgAlt1} />
        <BlockImage src={imgSrc2} alt={imgAlt2} />
        <StyledButton onClick={handleClick}>{buttonText}</StyledButton>
        <Description>{description}</Description>
      </StyledBlock>
    </StyledColumn>
  );
};

const Description = styled.p`
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
`;

const MainContainer = styled.main`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 800px;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 700px;
  max-width: 100%;
  margin: 167px 0 118px;

  @media (max-width: 991px) {
    margin: 40px 0;
  }
`;

const WelcomeTitle = styled.h1`
  color: #fff;
  font-size: 32px;
  text-align: center;
  margin-bottom: 40px;

  @media (max-width: 991px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
`;

const Content = styled.div`
  gap: 20px;
  display: flex;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;

  @media (max-width: 991px) {
    width: 80%;
  }
`;

const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border-bottom-width: 0.6px;
  backdrop-filter: blur(50px);
  overflow: hidden;
  position: relative;
  aspect-ratio: 0.85;
  flex-grow: 1;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.2px;
  padding: 40px 24px 25px;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 40px;
  }
`;

const BlockImage = styled.img`
  position: absolute;
  top: 0;
  width: 40%;
  object-fit: cover;
  object-position: center;
  max-width: 40%;

  @media (max-width: 991px) {
    width: 40%;
  }
`;

const StyledButton = styled.button`
  position: absolute;
  bottom: 180px;
  font-family: Roboto, sans-serif;
  justify-content: center;
  border-radius: 70px;
  background: linear-gradient(90deg, #007aff 0%, #007aff 100%);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.11) inset,
    0 10px 70px rgba(255, 255, 255, 0.05);
  padding: 10px 32px;
  border: none;
  color: white;
  cursor: pointer;

  @media (max-width: 991px) {
    padding: 10px 20px;
  }
`;

export default MyLandingPage;
