import * as React from "react";
import styled from "styled-components";
const languages = require("./assets/images/languages-logo.png");
const web = require("./assets/images/web-logo.png");
const backend = require("./assets/images/backend-logo.png");
const ml = require("./assets/images/ml-logo.png");
const version = require("./assets/images/vc-logo.png");
const deploy = require("./assets/images/deployment-logo.png");

const skills = [
  {
    icon: languages,
    title: "Programming Languages",
    description:
      "Python, Java, JavaScript, TypeScript, MATLAB, MIPS Assembly, Dart, HTML, CSS, C/C++, C#, SQL",
  },
  {
    icon: web,
    title: "Web Development Technologies",
    description:
      "React, Angular, Flutter, Bootstrap, HTML, CSS, TypeScript, Java Spring Boot, JavaFX, Three.js",
  },
  {
    icon: backend,
    title: "Backend Development Frameworks",
    description: "Spring Boot, Flask, PostgreSQL, Firebase",
  },
  {
    icon: ml,
    title: "Data Science and Machine Learning Libraries",
    description:
      "Numpy, Pandas, Matplotlib, Scikit-learn, Tensorflow-Keras, OpenCV, Darts, Seaborn",
  },
  {
    icon: version,
    title: "Version Control and Collaboration Tools",
    description: "Git, Github, Trello, Figma",
  },
  {
    icon: deploy,
    title: "Infrastructure and Deployment",
    description: "Linux, SonarQube, AWS(EC2), Heroku",
  },
];

function MySkills() {
  return (
    <Container id="skills-section">
      <Heading>Software and Programming skills</Heading>
      <Subheading>
        Through a blend of hands-on experience, extracurricular involvement, and
        coursework, I've cultivated a broad skill set in software development
        and data science, alongside a capacity for both collaborative teamwork
        and independent research.
      </Subheading>
      <SkillsGrid>
        <SkillsRow>
          {skills.slice(0, 3).map((skill, index) => (
            <SkillCard key={index}>
              <SkillIcon src={skill.icon} alt={`${skill.title} icon`} />
              <SkillTitle>{skill.title}</SkillTitle>
              <SkillDescription>{skill.description}</SkillDescription>
            </SkillCard>
          ))}
        </SkillsRow>
        <SkillsRow>
          {skills.slice(3).map((skill, index) => (
            <SkillCard key={index}>
              <SkillIcon src={skill.icon} alt={`${skill.title} icon`} />
              <SkillTitle>{skill.title}</SkillTitle>
              <SkillDescription>{skill.description}</SkillDescription>
            </SkillCard>
          ))}
        </SkillsRow>
      </SkillsGrid>
    </Container>
  );
}

export default MySkills;

const Container = styled.section`
  align-items: center;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  flex-direction: column;
`;

const Heading = styled.h2`
  color: var(--Foundation-White-Light, #fefefe);
  text-align: center;
  letter-spacing: 1.2px;
  font: 700 40px Lato, sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Subheading = styled.p`
  color: var(--newwwwwwwwwwww, #959595);
  padding-left: 300px;
  padding-right: 300px;
  text-align: justify;
  letter-spacing: 0.6px;
  margin-top: 18px;
  font: 500 20px/160% Lato, sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const SkillsGrid = styled.div`
  padding-left: 120px;
  padding-right: 120px;
  justify-content: center;
  align-self: stretch;
  display: flex;
  margin-top: 50px;
  // width: 100%;
  flex-direction: column;

  @media (max-width: 991px) {
    // max-width: 100%;
    margin-top: 30px;
    padding: 0 20px;
  }
`;

const SkillsRow = styled.div`
  gap: 20px;
  display: flex;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }

  &:not(:first-child) {
    margin-top: 40px;
  }
`;

const SkillCard = styled.article`
  align-items: center;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.04);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  padding: 34px;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const SkillIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 60px;
`;

const SkillTitle = styled.h3`
  color: var(--purp, #d514ce);
  text-align: center;
  letter-spacing: 0.72px;
  margin-top: 16px;
  font: 700 20px/160% Lato, sans-serif;
`;

const SkillDescription = styled.p`
  color: var(--newwwwwwwwwwww, #959595);
  text-align: center;
  letter-spacing: 0.6px;
  align-self: stretch;
  margin-top: 34px;
  font: 500 20px/32px Lato, sans-serif;

  @media (max-width: 991px) {
    margin: 0 3px;
  }
`;
