import * as React from "react";
import styled from "styled-components";
import { useState } from "react";
const hirise = require("./assets/images/hirise-img.png");
const poison_ivy = require("./assets/images/poison_ivy.png");
const gps_img = require("./assets/images/GPS-img.png");
const puzzle_img = require("./assets/images/puzzle_games.png");
const flashlearn = require("./assets/images/flashlearn-img.png");
const estore = require("./assets/images/estore-img.png");

const projects = [
  {
    id: 1,
    imageSrc: hirise,
    title: "Mars Image Classifier",
    description:
      "- Classified Mars’ Images using NASA’s HIRISE dataset and Convolutional Neural Network models. Analyzed and evaluated the performance between different convolutional network models and implemented data augmentation methods to increase model’s accuracy\n - Developed a deep learning model for classifying Mars orbital images using Convolutional Neural Networks (CNNs) on NASA's Planetary Data System (PDS) HiRISE dataset.\n - Implemented transfer learning from AlexNet architecture, fine-tuning it for Mars image classification.\n - Investigated the impact of data augmentation techniques, including random flipping and rotation, on model performance.\n - Conducted experiments to analyze the effects of different augmentation methods on accuracy and loss metrics.\n - Evaluated model performance using classification accuracy, confusion matrices, and training/validation loss graphs.",
    skills: ["Python", "TensorFlow", "CNN", "Data Augmentation"],
  },
  {
    id: 2,
    imageSrc: gps_img,
    title: "GPS Tracker",
    description:
      "- Developed a GPS data processing script for uphill detection and visualization, utilizing Python libraries such as Pynmea2 and Simplekml.\n - Implemented data cleaning techniques to filter out invalid or erroneous GPS readings, including removing stopped car data points and sensor anomalies.\n - Utilized GPS data parsing to extract essential information like latitude, longitude, speed, and altitude, organizing it into a structured Pandas DataFrame for efficient processing.\n - Employed algorithms to detect uphill segments based on changes in altitude and vehicle speed, with consideration given to slope thresholds and minimum distance traveled.\n - Generated dynamic Keyhole Markup Language (KML) files using the Simplekml library, providing color-coded visualization of uphill and downhill paths on Google Maps.\n - Conducted an ethical analysis to ensure compliance with privacy and consent standards, highlighting the importance of transparent data usage and informed consent.",
    skills: ["Python", "Pandas", "Simplekml", "Data Processing"],
  },
  {
    id: 3,
    imageSrc: poison_ivy,
    title: "Poison Ivy Detector",
    description:
      "- Developed a computer vision system using MATLAB to classify poison ivy from other plants.\n - Implemented image preprocessing techniques including noise reduction and color space manipulation.\n - Employed segmentation methods such as k-means clustering with strong edge detection to isolate center leaves.\n- Utilized morphology operations for further noise reduction and removal of small regions.\n- Designed a classification system based on features like leaf area, convex area, and number of corners.\n- Demonstrated ability to work in a semi-supervised environment, adapting techniques to handle challenging scenarios like overlapping leaves.",
    skills: ["MATLAB", "Computer Vision", "Image Processing"],
  },
  {
    id: 4,
    imageSrc: puzzle_img,
    title: "Puzzle Games Collection",
    description:
      "- Created a collection of puzzle games including Sudoku, Hoppers Frogs, Rush Hour, Memory Matching with solvers and graphical user-interface using JavaFX",
    skills: ["Java", "JavaFX", "Scene Builder", "Game Development"],
  },
  {
    id: 5,
    imageSrc: estore,
    title: "eCommerce Website",
    description:
      "- Part of a 5-person team that developed and implemented an eCommerce website, followed SCRUM methodology to deliver the application within 5 sprints \n - Responsible for designing efficient user interactions using TypeScript, Angular, HTML, CSS, Bootstrap \n Created and maintained architecture for Restful API using Spring Boot to handle all customer and admin actions \n Successfully implemented test cases using JUnit5 and Mockito, attaining a robust 90% code coverage, which was rigorously validated using SonarQube to enhance software robustness, security, and performance \n - Utilized Git for version control and Trello for project management, ensuring seamless collaboration and timely delivery of the project",
    skills: [
      "TypeScript",
      "Angular",
      "Bootstrap",
      "SonarQube",
      "Java Spring Boot",
      "SCRUM",
      "Git",
    ],
  },
  {
    id: 6,
    imageSrc: flashlearn,
    title: "FlashLearn App",
    description:
      "- Developed a web-based application that utilizes ChatGPT's API to assist users in summarizing content, creating flashcards, and generating quizzes from multimedia sources including YouTube links, videos, audio, and images \n - Employed Python and Flask for the robust backend and React for the responsive frontend, resulting in a seamless and intuitive end-user experience",
    skills: ["Python", "Flask", "React", "OpenCV", "API Integration"],
  },
  {
    id: 7,
    imageSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/6d26d31dfafee6aa39cbad771f4f68b0e7157ccfe95900130ba30f3634134213?apiKey=64874d91905343b0a6103f03c78f2eb8&",
    title: "Assembly Vigenere Cipher",
    description:
      "- Implemented a Vigenère cipher in MIPS assembly to deepen my understanding of MIPS architecture and memory management in assembly language.",
    skills: ["MIPS", "Assembly", "Cryptography"],
  },
];

function MyProjects() {
  const [expandedProject, setExpandedProject] = useState(null);

  const toggleDescription = (id) => {
    setExpandedProject(expandedProject === id ? null : id);
  };

  return (
    <Wrapper id="projects-section">
      <Heading>My latest work</Heading>
      <Subheading>
        These are projects I've worked on for both my coursework and personal
        interests.
      </Subheading>
      <ProjectsGrid>
        <ProjectsRow>
          {projects.slice(0, 3).map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              isExpanded={expandedProject === project.id}
              toggleDescription={() => toggleDescription(project.id)}
            />
          ))}
        </ProjectsRow>
        <ProjectsRow>
          {projects.slice(3).map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              isExpanded={expandedProject === project.id}
              toggleDescription={() => toggleDescription(project.id)}
            />
          ))}
        </ProjectsRow>
      </ProjectsGrid>
    </Wrapper>
  );
}

const ProjectCard = ({ project, isExpanded, toggleDescription }) => {
  return (
    <ProjectCardWrapper>
      <ProjectImage src={project.imageSrc} alt={project.title} />
      <ProjectDetails>
        <ProjectTitle>{project.title}</ProjectTitle>
        <ProjectSkills>
          {project.skills.map((skill, index) => (
            <SkillBadge key={index}>{skill}</SkillBadge>
          ))}
        </ProjectSkills>
        <ProjectDescription>
          {isExpanded
            ? project.description
            : `${project.description.slice(0, 100)}...`}
        </ProjectDescription>
        <ReadMoreButton onClick={toggleDescription}>
          {isExpanded ? "Read Less" : "Read More"}
        </ReadMoreButton>
      </ProjectDetails>
    </ProjectCardWrapper>
  );
};

const ReadMoreButton = styled.button`
  background-color: transparent;
  border: none;
  color: var(--Foundation-White-Light, #fefefe);
  font: 400 12px/19px Lato, sans-serif;
  cursor: pointer;
  margin-top: 7px;
`;

export default MyProjects;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h2`
  color: var(--Foundation-White-Light, #fefefe);
  text-align: justify;
  letter-spacing: 1.2px;
  align-self: center;
  font: 700 40px Lato, sans-serif;
`;

const Subheading = styled.p`
  color: var(--newwwwwwwwwwww, #959595);
  text-align: justify;
  letter-spacing: 0.6px;
  align-self: center;
  font: 500 20px/160% Lato, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ProjectsGrid = styled.div`
  justify-content: space-between;
  align-content: center;
  row-gap: 24px;
  flex-wrap: wrap;
  margin-top: 38px;
  // width: 100%;
  padding: 0 100px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const ProjectsRow = styled.div`
  gap: 20px;
  display: flex;
  padding-bottom: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const ProjectCardWrapper = styled.article`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: var(--foundation-white-normal-active, #c6c6c6);
  text-align: justify;
  // text-transform: capitalize;
  height: 100%; /* Set a fixed height */
  width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const ProjectImage = styled.img`
  aspect-ratio: 1.39;
  object-fit: auto;
  object-position: center;
  width: 100%;
  border-radius: 16px 16px 0 0; /* Rounded corners at the top */
`;

const ProjectDetails = styled.div`
  white-space: pre-line;
  justify-content: space-between;
  border-radius: 0 0 16px 16px;
  background-color: rgba(255, 255, 255, 0.08);
  display: flex;
  flex-direction: column;
  padding: 10px 16px 10px 13px;
  @media (max-width: 991px) {
    padding-right: 20px;
  }
`;

const ProjectTitle = styled.h3`
  letter-spacing: 0.48px;
  font: 700 16px/160% Lato, sans-serif;
`;

const ProjectDescription = styled.p`
  letter-spacing: 0.36px;
  margin-top: 7px;
  font: 400 12px/19px Lato, sans-serif;
`;

const ProjectSkills = styled.div`
  display: flex;
  // gap: 8px;
  flex-wrap: wrap;
`;

const SkillBadge = styled.span`
  background-color: #d514ce; /* Purple background */
  border-radius: 8px; /* Slightly more rounded corners */
  padding: 4px 8px; /* Increase padding for better readability */
  font-size: 12px; /* Increase font size */
  color: #ffffff; /* White text for contrast */
  margin: 4px; /* Add margin between badges */
`;
/// EXPERIMENTAL CODE
// import * as React from "react";
// import styled from "styled-components";
// import { useState } from "react";
// const hirise = require("./assets/images/hirise-img.png");
// const poison_ivy = require("./assets/images/poison_ivy.png");
// const gps_img = require("./assets/images/GPS-img.png");
// const puzzle_img = require("./assets/images/puzzle_games.png");
// const flashlearn = require("./assets/images/flashlearn-img.png");
// const estore = require("./assets/images/estore-img.png");

// const projects = [
//   {
//     id: 1,
//     imageSrc: hirise,
//     title: "Mars Image Classifier",
//     description:
//       "Classified Mars’ Images using NASA’s HIRISE dataset and Convolutional Neural Network models...",
//     skills: ["Python", "CNN", "Data Augmentation"],
//   },
//   {
//     id: 2,
//     imageSrc: gps_img,
//     title: "GPS Tracker",
//     description:
//       "Developed a GPS data processing script for uphill detection and visualization...",
//     skills: ["Python", "Pynmea2", "Simplekml"],
//   },
//   {
//     id: 3,
//     imageSrc: poison_ivy,
//     title: "Poison Ivy Detector",
//     description:
//       "Developed a computer vision system using MATLAB to classify poison ivy from other plants...",
//     skills: ["MATLAB", "Image Processing"],
//   },
//   {
//     id: 4,
//     imageSrc: puzzle_img,
//     title: "Puzzle Games Collection",
//     description:
//       "Created a collection of puzzle games including Sudoku, Hoppers Frogs, Rush Hour, Memory Matching...",
//     skills: ["Java", "JavaFX"],
//   },
//   {
//     id: 5,
//     imageSrc: estore,
//     title: "eCommerce Website",
//     description:
//       "Part of a 5-person team that developed and implemented an eCommerce website...",
//     skills: ["TypeScript", "Angular", "Spring Boot"],
//   },
//   {
//     id: 6,
//     imageSrc: flashlearn,
//     title: "FlashLearn App",
//     description:
//       "Developed a web-based application that utilizes ChatGPT's API to assist users in summarizing content...",
//     skills: ["Python", "Flask", "React"],
//   },
//   {
//     id: 7,
//     imageSrc:
//       "https://cdn.builder.io/api/v1/image/assets/TEMP/6d26d31dfafee6aa39cbad771f4f68b0e7157ccfe95900130ba30f3634134213?apiKey=64874d91905343b0a6103f03c78f2eb8&",
//     title: "Assembly Vigenere Cipher",
//     description: "A vigenere cipher that was written in MIPS assembly...",
//     skills: ["MIPS", "Assembly"],
//   },
// ];

// function MyProjects() {
//   const [expandedProject, setExpandedProject] = useState(null);

//   const toggleDescription = (id) => {
//     setExpandedProject(expandedProject === id ? null : id);
//   };

//   return (
//     <Wrapper id="projects-section">
//       <Heading>My latest work</Heading>
//       <Subheading>
//         These are projects I've worked on for both my coursework and personal
//         interests.
//       </Subheading>
//       <ProjectsGrid>
//         <ProjectsRow>
//           {projects.slice(0, 3).map((project) => (
//             <ProjectCard
//               key={project.id}
//               project={project}
//               isExpanded={expandedProject === project.id}
//               toggleDescription={() => toggleDescription(project.id)}
//             />
//           ))}
//         </ProjectsRow>
//         <ProjectsRow>
//           {projects.slice(3).map((project) => (
//             <ProjectCard
//               key={project.id}
//               project={project}
//               isExpanded={expandedProject === project.id}
//               toggleDescription={() => toggleDescription(project.id)}
//             />
//           ))}
//         </ProjectsRow>
//       </ProjectsGrid>
//     </Wrapper>
//   );
// }

// const ProjectCard = ({ project, isExpanded, toggleDescription }) => {
//   return (
//     <ProjectCardWrapper>
//       <ProjectImage src={project.imageSrc} alt={project.title} />
//       <ProjectDetails>
//         <ProjectTitleWrapper>
//           <ProjectTitle>{project.title} "asdasd"</ProjectTitle>
//           <ProjectSkills>
//             {/* {project.skills.map((skill, index) => (
//               <SkillBadge key={index}>{skill}</SkillBadge>
//             ))} */}
//             <SkillBadge>"dghdf"</SkillBadge>
//           </ProjectSkills>
//         </ProjectTitleWrapper>
//         <ProjectDescription>
//           {isExpanded
//             ? project.description
//             : `${project.description.slice(0, 100)}...`}
//         </ProjectDescription>
//         <ReadMoreButton onClick={toggleDescription}>
//           {isExpanded ? "Read Less" : "Read More"}
//         </ReadMoreButton>
//       </ProjectDetails>
//     </ProjectCardWrapper>
//   );
// };

// const ReadMoreButton = styled.button`
//   background-color: transparent;
//   border: none;
//   color: var(--Foundation-White-Light, #fefefe);
//   font: 400 12px /19px Lato, sans-serif;
//   cursor: pointer;
//   margin-top: 7px;
// `;

// const Wrapper = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

// const Heading = styled.h2`
//   color: var(--Foundation-White-Light, #fefefe);
//   text-align: justify;
//   letter-spacing: 1.2px;
//   align-self: center;
//   font: 700 40px Lato, sans-serif;
// `;

// const Subheading = styled.p`
//   color: var(--newwwwwwwwwwww, #959595);
//   text-align: justify;
//   letter-spacing: 0.6px;
//   align-self: center;
//   font: 500 20px/160% Lato, sans-serif;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const ProjectsGrid = styled.div`
//   justify-content: space-between;
//   align-content: center;
//   row-gap: 24px;
//   flex-wrap: wrap;
//   margin-top: 38px;
//   // width: 100%;
//   padding: 0 100px;
//   @media (max-width: 991px) {
//     max-width: 100%;
//     margin-top: 40px;
//     padding: 0 20px;
//   }
// `;

// const ProjectsRow = styled.div`
//   gap: 20px;
//   display: flex;
//   padding-bottom: 20px;
//   @media (max-width: 991px) {
//     flex-direction: column;
//     align-items: stretch;
//     gap: 0px;
//   }
// `;

// const ProjectCardWrapper = styled.article`
//   display: flex;
//   flex-grow: 1;
//   flex-direction: column;
//   color: var(--foundation-white-normal-active, #c6c6c6);
//   text-align: justify;
//   text-transform: capitalize;
//   height: 100%; /* Set a fixed height */
//   width: 100%;
//   @media (max-width: 991px) {
//     margin-top: 40px;
//   }
// `;

// const ProjectImage = styled.img`
//   aspect-ratio: 1.39;
//   object-fit: auto;
//   object-position: center;
//   width: 100%;
//   border-radius: 16px 16px 0 0; /* Rounded corners at the top */
// `;

// const ProjectDetails = styled.div`
//   white-space: pre-line;
//   justify-content: space-between;
//   border-radius: 0 0 16px 16px;
//   background-color: rgba(255, 255, 255, 0.08);
//   display: flex;
//   flex-direction: column;
//   padding: 10px 16px 10px 13px;
//   @media (max-width: 991px) {
//     padding-right: 20px;
//   }
// `;

// const ProjectTitleWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// `;

// const ProjectTitle = styled.h3`
//   letter-spacing: 0.48px;
//   font: 700 16px/160% Lato, sans-serif;
// `;

// const ProjectSkills = styled.div`
//   display: flex;
//   gap: 8px;
//   flex-wrap: wrap;
// `;

// const SkillBadge = styled.span`
//   background-color: pink;
//   border-radius: 4px;
//   padding: 2px 8px;
//   font-size: 12px;
//   color: #fff;
// `;

// const ProjectDescription = styled.p`
//   letter-spacing: 0.36px;
//   margin-top: 7px;
//   font: 400 12px/19px Lato, sans-serif;
// `;

// export default MyProjects;
